.container {
  display: flex;
  height: 100%;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
  background: #FFFFFF;
  border-radius: 24px;
  padding: 28px;
  box-sizing: border-box;
  & > * {
    width: 100%;
  }
}

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
  & > * {
    width: 100%;
  }
}

.uploaders {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.banners {
  display: flex;
  flex-wrap: wrap;

  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.img {
  width: 210px;
  height: 160px;
  padding: 15px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    bottom: 0;
    transition: all .3s ease;
  }

  &:hover:after {
    background-color: rgba(#000, .3);
  }
}

.img:hover > .deletePhotoBtn {
  z-index: 2;
}

.deletePhotoBtn {
  font-family: 'graphic';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  background-color: #b21e1e;
  color: #fff;
  text-shadow: none;
  border: none;
  outline: none;
  padding: 10px;
  font-weight: 500;
  font-size: 16px;
  border-radius: 3px;
  transition: all .3s ease;
  opacity: .5;
  z-index: -1;

  &:hover {
    opacity: 1;
  }


  &:hover > button {

  }
}

.dropzone {
  padding: 66px;
}

.container textarea {
  font-family: graphic, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: -0.02em;
  color: black;
  box-sizing: border-box;
  height: 130px;
  padding: 11px 20px;
  border: 1px solid #97D6B9;
  border-radius: 6px;
  outline: none;
  width: 100%;
  resize: none;
}

.fieldTitle {
  margin-top: 0;
  margin-bottom: 10px;
}

.hideContent {
  position: relative;
  overflow: hidden;
  height: 314px;
  &:after {
    content: "";
    display: block;
    height: 80px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0), #fff 75%);
  }
}