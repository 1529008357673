.container {
  text-align: left;
}

.label {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  margin: 0 0 10px 0;
  color: #1D1F23;
  i {
    color: tomato;
  }
}

.inputContainer {
  display: flex;
  align-items: center;
  flex-shrink: 1;
}

.inputContainer .input {
  width: 100%;
  display: block;
  min-height: 40px;
  border: 1px solid #E7E7E6;
}

.postfix {
  margin-right: -8px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
}

.error {
  text-align: left;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  margin: 5px 5px 0 5px;
  color: #F34C4C;
}